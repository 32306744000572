.buttonBox{
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    justify-content: center !important;
    width: 100% !important;
    margin: 3px !important;
}
.buttonsize{
    margin: 5px !important;
    width: 40% !important;
}

@media only screen and (min-width: 769px) and (max-width : 992px){
    .subCatbutton {
     margin: 5px !important;
     width: 70% !important;
    }
    .topHeadersWrapper{
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .buttonsize{
        margin: 1px !important;
        font-size: 2vw !important;
    }
}

@media only screen and (min-width: 601px) and (max-width : 768px){
    .subCatbutton {
     margin: 5px !important;
     width: 100% !important;
    }
    .topHeadersWrapper{
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .buttonsize{
        margin: 1px !important;
        font-size: 2vw !important;
    }
}

@media only screen and (max-width: 600px){
    .subCatbutton {
     margin: 5px !important;
     width: 100% !important;
    }
    .topHeadersWrapper{
        display: inline-flex;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center !important;
    }
    .buttonsize{
        margin: 1px !important;
        font-size: 2vw !important;
    }
}

