.cardCnt {
  max-width: 275px;
  /* display: inline-block; */
  float: left;
  margin-right: 25px;
  cursor: pointer;
}

.cardCnt:hover {
  box-shadow: 0px 11px 13px 0px rgba(0, 0, 0, 0.2),
    0px 11px 11px 0px rgba(0, 0, 0, 0.14),
    0px 12px 11px -1px rgba(0, 0, 0, 0.12);
}

.modalGridBody {
  margin: 10px auto 0 !important;
  outline: none;
}

.modalGridBody .bigSize {
  margin: 25px auto !important;
  outline: none;
}

.forgotPwdContainer.fullWidth {
  max-height: 100vh !important;
  overflow: auto;
  padding-bottom: 25px;
}
.dashboardMainContainer {
  width: 100%;
  overflow-x: hidden;
  padding: 10px;
}
.cardStyleForDashboardItems {
  min-width: 100px;
  min-height: 200px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
}
.cardStyleForDashboardItemsBody {  
  max-width: calc(100%-16px);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}
.buttonWrapper {
  display: flex;
  justify-content:center;
}
.buttonWrapper > Button {
  margin: 5px;
  text-transform: none !important;
  width: 135px;
}
.poupupContent{
  text-align: center;
}
/* Cart page */
article {
  width: 80%;
  margin: auto;
}

.cart_box img {
  width: 50px;
  height: 50px;
}
.cart_img {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}

.cart_img p {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 50px;
}

.cart_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  border-bottom: 2px solid skyblue;
  padding-bottom: 5px;
}

.cart_box div:nth-child(2) button {
  padding: 5px 10px;
  font-weight: bold;
  margin-right: 5px;
}

.cart_box div:nth-child(3) button {
  padding: 5px 10px;
  font-weight: bold;
  letter-spacing: 2px;
  border: none;
  outline: none;
  color: white;
  background-color: skyblue;
  border-radius: 5px;
  cursor: pointer;
}
.cart_box div:nth-child(3) button:hover {
  background-color: darkseagreen;
}
.cart_box div:nth-child(3) span {
  background-color: lightgreen;
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.total span{
 display: inline-flex;
 justify-content:space-between;

}
.total{
  margin-bottom: 20px;
}
.total span:nth-child(1) {
  font-size: 1rem;
  color: skyblue;
  font-weight: bold;
  letter-spacing: 2px;
  float: left;
}

.total span:nth-child(2) {
  font-size: 1.2rem;
  color: green;
  font-weight: bold;
  display: inline-flex;
  float: right;
  padding-right: 20px;
}
.cartButtons{
  display: inline-flex;
}
#cartDrawer {
 text-align: center;
}
.paymentButtons{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.paymentButtons Button{
  margin: 10px;
  color: white;
}
/* Animated arrow */
.arrow,
.arrow:before{
  position: absolute;
  left: 50%
}
.arrow{
  width: 20px;
  height: 20px;
  top: 50%;
  margin: -20px 0 0 -20px;
  -webkit-transform: rotate(145deg);
  border-left: none;
  border-top: none;
  border-right: 2px rgb(11, 11, 11) solid;
  border-bottom: 2px rgb(11, 11, 11) solid;
}
  
.arrow:before{
  content: '';
  width: 10;
  height: 10px;
  top: 50%;
  margin: -10px 0 0 -10px;
  border-left: none;
  border-top: none;
  -webkit-transform: rotate(145deg);
  border-right: 2px rgb(11, 11, 11) solid;
  border-bottom: 2px rgb(11, 11, 11) solid;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: arrow;
}
@keyframes arrow{
  0%{
    opacity: 1}
  
  100%{
    opacity: 0;
    transform: translate(-10px, -10px);
    -webkit-transform: rotate(145deg);
  }
  }
 
  
  @media screen and (max-width: 639px) {
    #cartDrawer {
      width: 100% !important;
      margin: auto;
      text-align: center;
    }
    .cardStyleForDashboardItems {
      min-width: 100px;
      min-height: 200px;
      display: flex;
      flex-wrap: wrap;
      overflow-x: scroll;
      flex-direction: column;
      justify-content: center;
    }
    .paymentButtons{
      display: inline-flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;
    }
    
  }
  
  
  @media screen and (min-width: 640px) {
    .paymentButtons{
      display: inline-flex;
      flex-direction: row;
      justify-content: space-between;
      
    }
  }
  
  