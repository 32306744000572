.mainHeader{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: large;
    font-weight: 600;
    font-family: emoji;
    color: blue;

}
.bookingBtn{
    background-color: #3ECF8E;
    border: none;
    border-radius: 5px;
    font-size: smaller;
    font-family: emoji;
}
.theadBtn{
    background:grey;
    color: whitesmoke;
}