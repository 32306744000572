.scrollable-container-weather{
    height: 150px !important;
}

.scrollable-container-weather h2{
    color: #3ECF8E !important ;
    font-weight: 600 !important;
}
.bt-disabled{
    background-color: rgb(177, 174, 174);
    border: rgb(177, 174, 174) ;
}