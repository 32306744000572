.update-banner-container {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  display: flex;
  align-items: center;
  padding: 270px 0 190px;
  background-attachment: fixed;
}
.BannerHeading{
  font-size: 40px !important;
}
.update-banner-container h1{
  font-size: 28px;
  margin: 0 0 20px;
  line-height: 42px;
  font-weight: 600
}
.buttonIcon{
  width: 16px;
  height: 16px;
}
.update-banner-container .bodycopy{
  font-size: 20px;

}
@media screen and (max-width: 769px) {
  .update-banner-container {
    padding: 140px 0 100px;
  }
  .update-banner-container h1{
    font-size: 26px; 
    line-height: 40px;
}
}
