.main_container {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
 
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.main_container-card {
 
  /* padding: 1rem; */
  flex: 1;
   

}
.main_container-card:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

}

.main_container-card:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
 

}

.main_container--fleetInfoFooter {
  display: flex;
  justify-content: space-around;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;


}

.main_container--fleetInfoFooter >*{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .main_container {
    flex-direction: column;
    align-items: center;
    border: none;
  }
}
