.signinPageContainer {
    padding-left: 0 !important;
    padding-right: 0 !important;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    text-align: center;
}

.signinPageContainer.default {
    background-image: url("/assets/img/bgImg.jpg");
}

.signinPageContainer.investment {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/beekeeping.jpg") !important;
}

.signinPageContainer.veterinary {
    background-position: bottom;
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/gbrApp/image5.webp") !important;
}

.signinPageContainer.grower {
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/gbrApp/image2.webp") !important;
}

.signinPageContainer.investor {
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/gbrApp/image1.jpg") !important;
}

.signinPageContainer.buyer {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrApp/image6.webp") !important;
}

.signinPageContainer.reseller {
    background-image: url("/assets/img/seller.jpg");
}

.signinPageContainer.agent {
    background-position: bottom;
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrApp/image10.webp") !important;
}

.signinPageContainer.merchant {
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/gbrApp/image7.webp")  !important;
}

.signinPageContainer.driver {
    background-image:linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url("../../../../images/gbrApp/image9.webp")  !important;
}

.loginOuterContainer {
    margin: 90px auto 0 !important;
    
}

.loginContainer{
    min-height: 350px;
    background-color: #FAFAFA;
    box-shadow: 2px 5px 25px #888888;
    border-radius: 5px;
    text-align: center;
    padding: 25px 45px 10px 45px;
    max-width: 350px !important;
    margin: 0 auto !important;
}
.closeBtn{
    width: 30px;
}
.closeBtn a{
    color: #000 !important;
}
.loginFields {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}


.loginBtn {
    margin-top: 5px !important;
    color: #FFF !important;
}

.rememberMe {
    float: left;
    text-align: left;
    font-size: 12px !important;
    color: #969798 !important;
    margin-top: 15px;
    letter-spacing: .5px;
}

.rememberMe span:nth-child(1) {
    color: #68696D !important;
}

.rememberMe input[type="checkbox"]:checked {
    color: #F3BD48 !important;
}

.rememberMe span input[type=checkbox] {
    border: 2px solid #163cd3 !important;
    background-color: #132364 !important;
}

.rememberMe span:nth-child(2) {
    font-size: 12px !important;
    color: #68696D !important;
}

.forgotPwdLink {
    font-size: 12px;
    color: #CBCDCD;
    margin-top: 12px !important;
    /*margin-bottom: 10px !important;*/
    letter-spacing: .5px;
    cursor: pointer;
}

.forgotPwdLink:hover {
    color: #68696D;
}

.signinPageContainer.farmerAdvisor{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrApp/image5.webp") !important;
}
.signinPageContainer.machineryProvider{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrCBN/CBNImg4.webp") !important;
}
.signinPageContainer.nutritionCompanies{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrCBN/CBNImg5.webp") !important;
}
.signinPageContainer.storageProvider{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrCBN/CBNImg8.webp") !important;
}
.signinPageContainer.inputCompany{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrCBN/CBNImg9.webp") !important;
}
.signinPageContainer.transporter{
    background-image:  linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),url("../../../../images/gbrCBN/CBNImg7.webp") !important;
}


.backBtn{
    text-decoration: none !important;
    color: inherit !important;

}