.loadMoreOrder {
  font-size: 13px;
  background-color: #3f51b5;
  color: white;
  border-radius: 5px;
  border-style: none;
  width: 100%;
  height: 30px;
}

.main_contaier {
  margin-top: 2rem;
  padding: 0 2rem;
}

.main_container-row {
  display: flex;
  gap: 1rem;
justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.main_container-row > * {
  flex: 1;
  max-width: 8rem;
}
